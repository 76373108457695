<template>
  <div>
    <div class="body-title">Publications</div>
    <div class="body-content">
      <ul>
        <li>
          <p class="bold">
            <a href="https://arxiv.org/abs/2202.05423">
                    Understanding Curriculum Learning in Policy Optimization for Online Combinatorial Optimization
            </a></p>
            <p>Runlong Zhou, <span class="bold">Zelin He</span>, Yuandong Tian, Yi Wu, Simon S. Du</p>
  
          <p class="italic">Transactions on Machine Learning Research (TMLR)</p>
          <p>We formulate of canonical online Combinatorial Optimization problems as Latent MDPs and give convergence guarantee of Natural
            Policy Gradient on LMDPs. We show effectiveness of Curriculum Learning through the perspective of relative conditional number.</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicationView',
  metaInfo: {
    title: 'Publications',
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
</style>