import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import HomeView from './views/Home.vue';
import PublicationView from './views/Publication.vue';
import CVView from './views/CV.vue';
import ProjectsView from './views/Projects.vue';
import ContactView from './views/Contact.vue';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active-link',
  routes: [
    { path: '/', component: HomeView },
    { path: '/publication', component: PublicationView },
    { path: '/resume', component: CVView },
    { path: '/projects', component: ProjectsView },
    { path: '/contact', component: ContactView }
  ]
});


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
