<template>
  <div>
    <div class="body-title">Personal Links</div>
    <div class="body-content contact-list">
    <div><img src = "@/assets/envelope-solid.svg" alt="Email icon"/> <div><p>Email:</p><a href="zelinh2@uw.edu">zelinh2@uw.edu</a></div></div>
    <div><img src = "@/assets/linkedin.svg" alt="linkedin icon"/>  <div><p>LinkedIn:</p> <a href="https://linkedin.com/in/zelin-he-231169241/">Zelin He</a></div></div>
    <div><img src = "@/assets/github.svg" alt="gayhub icon"/> <div><p>Github:</p><a href="https://github.com/tsigave">Tsigave</a></div></div>
    <div><img src = "@/assets/google-scholar.svg" alt="google scholar icon"/> <div><p>Google Scholar:</p><a href="https://scholar.google.com/citations?user=cTz5N_AAAAAJ">Zelin He</a></div></div>
    <!-- Add other contact details or a form as needed -->
  </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  metaInfo: {
    title: 'Links',
  }
}
</script>

<style scoped>
.contact-list img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 10px 30px;
}

.contact-list > div {
  flex: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}
.contact-list > div p, .contact-list > div a{
  padding: 5px;
  margin: 0;
}

</style>