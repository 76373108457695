<template>
  <div>
    <div class="body-title">About me</div>
    <div class="body-content">
      <p>I am a Junior studying data science and mathematics at the University of Washington. I am very fortunate to work under the guidance of Professor Shaolei Simon Du and with Dr. Runlong Zhou.</p>
      <p>My research primarily focused on deep learning areas. I have worked on the theories part of reinforcement learning and am currently researching data selection for machine learning models.</p>
      <p>In addition to my research, I have also worked on computer systems, applications of machine learning, computer vision, database systems, and full-stack development.</p>
      <p>My goal is to continue developing machine learning theories, improving the efficiency of current machine learning models, and making the technology available and affordable to everyone.</p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'HomeView',
  metaInfo: {
    title: 'About me',
  }
}
</script>

<style scoped>
.body-content {
  padding: 40px;
}
</style>