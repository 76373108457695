<template>
    <div class="topbar shadowed">
        <div class="profile-image">
        <img src="@/assets/avatar.jpeg" alt="Profile Image">
        </div>
        <div class="name">Zelin He</div>
        <nav> <ul id="nav-bar">
            <li><router-link to="/" exact>Home</router-link></li>
            <li><router-link to="/publication">Publications</router-link></li>
            <li><router-link to="/resume">Resume</router-link></li>
            <!-- <li><router-link to="/projects">Projects</router-link></li> -->
            <li><router-link to="/contact">Links</router-link></li>
        </ul> </nav>
    </div>
    </template>
    
<script>
export default {
name: 'TopbarComponent'
}
</script>

<style scoped>
    
.topbar {
    width: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    overflow: auto;
    text-align: center;
    height: fit-content;
    top: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.topbar > * {
    align-content: center;
}

.name {
    margin-left: 20px;
    font-size: 18pt;
    font-weight: bold;
}

.description {
    font-size: 12pt;
    margin-bottom: 10px;
}

.profile-image img {
    width: 30px;
    height: 30px;
    margin: 10px 0 10px 20px;
    border-radius: 50%;
    border: 3px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


nav ul {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 20px;
    list-style: none;
}

nav li {
    font-size: 14pt;
    margin: 5px;
    border-radius: 4px;
    padding: 8px;
    transition: 0.3s;
}

nav li:hover {
    background-color: #ddd;
}

nav li:active {
    background-color: #ccc;
    transition: 0.1s;
}

.active-link {
    Color: #222;
    font-weight: bold;
}

nav a {
    text-decoration: none;
    color: #333;
}

@media only screen and (min-width: 1049px) {
    .topbar {
    display: none;
    }
    
}

@media only screen and (max-width: 588px) {
    .profile-image img {
    display: none;
    }
    nav li {
        padding: 8px 3px;
    }
    
}

@media only screen and (max-width: 532px) {
    .name {
        font-size: 14pt;
    }
}

</style>
