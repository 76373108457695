<template>
<div class="sidebar">
    <div class="sidebar-box  shadowed">
        <div class="profile-image">
        <img src="@/assets/avatar.jpeg" alt="Profile Image">
        </div>
        <div class="name">Zelin He</div>
        <div class="secondary-name">何泽林</div>
        <div class="description">Junior student studying data science in University of Washington</div>
    </div>
    <div class="sidebar-box  shadowed">
        <nav> <ul id="nav-bar">
            <li><router-link to="/" exact>Home</router-link></li>
            <li><router-link to="/publication">Publications</router-link></li>
            <li><router-link to="/resume">Resume</router-link></li>
            <!-- <li><router-link to="/projects">Projects</router-link></li> -->
            <li><router-link to="/contact">Links</router-link></li>
        </ul> </nav>
    </div>
</div>
</template>

<script>
export default {
name: 'SidebarComponent'
}
</script>

<style scoped>

.sidebar {
  width: 200px; /* Updated to 200px width */
  position: sticky;
  display: block;
  overflow: auto;
  text-align: center;
  height: fit-content;
  top: 20px;
  padding: 0 10px 10px 10px;
}

.sidebar-box {
  margin-bottom: 10px;
  padding: 15px; /* Updated padding to 10px */
  background-color: #f4f4f4;
}

.name {
  font-size: 20pt;
  font-weight: bold;
}

.secondary-name {
    font-size: 14pt;
    color: #666;
    margin-bottom: 10px;
}

.description {
    font-size: 12pt;
    margin-bottom: 10px;
}

.profile-image img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin-top: 20px;
  border: 3px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.sidebar-box:last-child {
  margin-top: 20px;
  padding: 10px;
}

nav ul {
  margin: 0;
  list-style: none;
}

nav li {
  margin: 5px;
  border-radius: 4px;
  padding: 8px;
  transition: 0.3s;
}

nav li:hover {
    background-color: #ddd;
}

nav li:active {
  background-color: #ccc;
  transition: 0.1s;
}

.active-link {
    Color: #222;
    font-weight: bold;
}

nav a {
  text-decoration: none;
  color: #333;
}

@media only screen and (max-width: 1050px) {
  .sidebar {
    display: none;
  }
  
}

</style>
