<template>
  <div id="app">
    <TopbarComponent />
    <div id="page-body">
      <SidebarComponent />
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <vue-canvas-nest :config="{color:'200,50,200',opacity: 0.8, zIndex: -2, count: 120}" ></vue-canvas-nest>
  </div>
</template>

<script>
import vueCanvasNest from 'vue-canvas-nest'
import SidebarComponent from './components/Sidebar';
import TopbarComponent from './components/Topbar';
import { RouterView } from 'vue-router';

export default {
  name: 'App',
  metaInfo: {
    title: 'Basic',
    titleTemplate: '%s | Zelin He 何泽林',
  },
  components: {
    vueCanvasNest, SidebarComponent, RouterView, TopbarComponent
  }
}
</script>

<style>

body {
  background-color: #ddd;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  font-size: 14pt;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  font-family: MiSans VF,MiSans,system-ui,sans-serif;
  flex: 1;
}

footer {
  color: #666;
  text-align: center;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

a {
  color: #52adc8;
}

.content > div > div {
  padding: 20px;
  background: white;
}

.body-title {
  margin-bottom: 20px;
  font-size: 24pt;
  font-weight: bold;
  text-align: center;
}

.body-content {
  margin-bottom: 20px;
}

.shadowed, .content > div > div {
  border-radius: 5px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);
}

#page-body {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.content {
  width: 800px; /* Set width to 800px */
  margin-left: 10px; /* 200px sidebar width + 10px space */
}


.profile-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 20px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav li {
  margin-bottom: 10px;
}

nav a {
  text-decoration: none;
  color: #333;
}

@media only screen and (max-width: 1050px) {
  .content {
    width: 90%;
  }
  
}

</style>
