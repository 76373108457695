<template>
  <div>
    <div class="body-title">Resume</div>
    <div class="body-content">
      <div class="centering"><a href="resume.pdf">Direct View</a></div>
      <div class="centering"><a href="resume.pdf" download>Download Resume</a></div>
      <pdf src="resume.pdf" :page="1">
        <template slot="loading">
          loading content here...
        </template>
      </pdf>
    </div>
  </div>
</template>
  
<script>
import pdf from 'pdfvuer'

export default {
  name: 'CVView',
  components: {
    pdf
  },
  metaInfo: {
    title: 'Resume',
  }
}
</script>
  

<style scoped>
.centering {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16pt;
}
</style>